import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/home';
import About from './pages/about';
import Projects from './pages/projects/projects';
import SingleProject from './pages/projects/singleProject';
import Auth from './pages/auth';
import Blog from './pages//blog/blog';

import './App.css';
import projects from './data/projects.json';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Projects} />

        <Route path="/blog" Component={Projects} />
        <Route path="/log" Component={Projects} />

        <Route path="/about" Component={Projects} />
        <Route path="/me" Component={Projects} />

        <Route path="/projects" Component={Projects} />
        <Route path="/project" Component={Projects} />

        <Route path="/auth" Component={Projects} />

        <Route path="/projects/:id" Component={SingleProject} />
        {//projects.map((project) => (
          //<Route key={project.id} path={`/projects/${project.id}`} element={<SingleProject/>} />
        //))
  }
      </Routes>
    </Router>
  );
}

export default App;
