import { Link } from 'react-router-dom';
import Title from "../title";

import ownStyles from './projectCard.module.css';

import ProjectsJSON from "../../data/projects.json";

type Props = {
    id: string
}

//TODO add tags, date, context

const ProjectCard = (props: Props) => {
    const project = ProjectsJSON.find((project) => project.id === props.id);

    if (!project) {
        return (
            <div className={ownStyles['project-card-container']}>
                <div className={ownStyles['project-card-body']}>
                    <Title text="Project not found" />
                    <p>Project not found</p>
                </div>
            </div>
        );
    }

    if (project === undefined) {
        return (
            <div className={ownStyles['project-card-container']}>
                <div className={ownStyles['project-card-body']}>
                    <Title text="Project not found" />
                    <p>Project not found</p>
                </div>
            </div>
        )
    }

    const year = project.date.substring(0, 4);
    const yearDigit1 = project.date.substring(0, 1);
    const yearDigit2 = project.date.substring(1, 2);
    const yearDigit3 = project.date.substring(2, 3);
    const yearDigit4 = project.date.substring(3, 4);


    const month = project.date.substring(5, 7);
    const day = project.date.substring(8, 10);

     // Handle image loading and error
     let projectImgSrc = null; // Fallback image source
     if (project.image) {
         try {
             projectImgSrc = require(`../../res/${project.image}`);
         } catch (error) {
         }
     }

    return (
        <Link to={`/projects/${props.id}`}>
            <div className={ownStyles['project-card-container']}>
                <div className={ownStyles['project-card-body']}>
                    <Title text={project.title} />
                    <p>{project.oneLiner}</p>
                    <div className={ownStyles['project-card-date-container']}>
                        <div className={ownStyles['project-card-date-digit']}>{yearDigit1}</div>
                        <div className={ownStyles['project-card-date-seperator']}>{yearDigit2}</div>
                        <div className={ownStyles['project-card-date-digit']}>{yearDigit3}</div>
                        <div className={ownStyles['project-card-date-seperator']}>{yearDigit4}</div>
                        <div className={ownStyles['project-card-date-digit']}></div>
                    </div>
                    <div className={ownStyles['project-card-diagonal-line']} />
                    <div className={ownStyles['project-card-diagonal-section']}>
                        {projectImgSrc && <img src={projectImgSrc} alt={project.title || 'Default Image'} />}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ProjectCard;