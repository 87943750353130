import React, { useEffect, useState } from "react";

import ownStyles from './singleProject.module.css';
import Header from "../../components/header/header";

import ProjectsJSON from "../../data/projects.json";
import { useParams } from "react-router-dom";



const SingleProject = () => {
    const { id } = useParams();
    const project = ProjectsJSON.find((project) => project.id === id);
    
    if (!project) {
        return (
            <>
                <Header title="dev" />
                <div>Project not found</div>
            </>
        )
    }

    const [projectImg, setProjectImg] = useState(null);
    const [projectImg2, setProjectImg2] = useState(null);
    const [projectImg3, setProjectImg3] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const img1 = await import(`../../res/${project.image}`).then((module) => module.default);
                setProjectImg(img1);
            } catch (error) {  }
            try {
                const img2 = await import(`../../res/${project.additional_image_1}`).then((module) => module.default);
                setProjectImg2(img2);
            } catch (error) {  }
            try {
                const img3 = await import(`../../res/${project.additional_image_2}`).then((module) => module.default);
                setProjectImg3(img3);
            } catch (error) {  }
        };

        fetchImages();
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to the top of the page
    }, [project]);

    //only store the year
    project.date = project.date.substring(0, 4);

    return (
        <>
            <Header title="dev" />
            <div className={ownStyles['project-title']}>
                <h1>{project.title}</h1>
            </div>
            <div className={ownStyles['description-content']}>
                <p className={ownStyles['description-single']}>{project.description}</p>
                <p className={ownStyles['description-single']}>{project.additional_description_1}</p>
                <p className={ownStyles['description-single']}>{project.additional_description_2}</p>
            </div>
            <div className={ownStyles['info-content']}>
                <div className={ownStyles['info-techs']}>
                    <div className={ownStyles['info-title']}>
                        Techs
                    </div>
                    <div>
                        {project.techs}
                    </div>
                </div>
                <div>
                    <div className={ownStyles['info-title']}>Year</div>
                    <div>{project.date}</div>
                </div>
                <div></div>
            </div>
            <div className={ownStyles['image']}>
                {projectImg && <img src={projectImg} alt={project.title} />}
            </div>
            <div className={ownStyles['image']}>
                {projectImg2 && <img src={projectImg2} alt={project.title} />}
            </div>
            <div className={ownStyles['image']}>
                {projectImg3 && <img src={projectImg3} alt={project.title} />}
            </div>
        </>
    )
}

export default SingleProject;