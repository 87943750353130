import Header from "../../components/header/header";
import ProjectCard from "../../components/projects/projectCard";
import Title from "../../components/title";

import ownStyles from './projects.module.css';
import projects from '../../data/projects.json';

type Project = {
    id: string;
};

const Projects = () => {
    return (
        <>
            <Header title="dev" />
            <div className={ownStyles['projects-title']}>
                <h1>journey</h1><h2>into my</h2><h1>projects</h1>
            </div>
            <div className={ownStyles['projects-description']}>
                <p>
                    From delving into the intricacies of <strong>C++</strong> and <strong>OpenGL</strong> to crafting immersive experiences in <strong>Unity</strong> and harnessing the power of <strong>React</strong> and <strong>TypeScript</strong>. I've ventured into the realms of <strong>VR</strong>, <strong>AR</strong>, and the cutting-edge <strong>HoloLens 2</strong> and <strong>Oculus</strong> technologies. Engaging with clients such as the <strong>Royal Canadian Navy</strong>, <strong>Rio Tinto</strong>, and <strong>L&R</strong>, I've applied my expertise to create impactful solutions. Each project is a testament to <strong>creativity</strong>, <strong>skill</strong>, and a <strong>passion</strong>. Dive in and explore!
                </p>
            </div>
            <div className={ownStyles['projects-list-title']}>
                <Title text="all projects" />
            </div>
            <div className={ownStyles['projects-container']}>
                {projects.map((project :Project) => (
                    <ProjectCard key={project.id} id={project.id} />
                ))}
            </div>
        </>
    );
}

export default Projects;