import Title from '../title';
import Emoticon from '../emoticon';
import { Link } from 'react-router-dom';
import ownStyles from './header.module.css';

type Props = {
    title?: string,
}
// <Emoticon emoticon="🌙" />
// <NavButton linkText='blog' link='/blog' />
// <NavButton linkText='projects' link='/projects' />
// <NavButton linkText='about' link='/about' />
const Header = (props: Props) => {
    if (props.title === undefined) props.title = "log";
    const headerTitle = "anthony-" + props.title;

    return (
        <header>
            <div id={ownStyles["header-leftSection"]}>
                <NavButton linkText={headerTitle} link='/' />
            </div>
            <div id={ownStyles["header-rightSection"]}>
                
            </div>
        </header>
    )
}

function NavButton({ linkText, link }: { linkText: string, link: string }) {
    return (
        <div className={ownStyles["link"]}>
            <Link to={link}>
                <Title text={linkText} />
            </Link>
        </div>
    )
}

export default Header;